import { formatDate } from 'util/format-date';
import { v1 as uuid } from 'uuid';

import { makeAutoObservable } from 'mobx';

import { MfaId } from '@frontend-monorepo/cyolo-auth';
import {
  DtoConvertible,
  IHasSystemUniqueID,
  IValidatable,
} from '@frontend-monorepo/cyolo-store';
import {
  getSystemUniqueID,
  UniqueIdentifiable,
  Validator as validator,
} from '@frontend-monorepo/cyolo-utils';

import {
  IBaseGroupObject,
  IUpdatableUserObject,
  IUserObject,
  UserAPI,
} from '../../services/api';
import IdentityProviderDescriptor from '../identity-provider-descriptor';

/**
 * A system user entity
 */

export enum Status {
  WAITING_FOR_ENROLLMENT = 'Waiting for enrollment',
  ACTIVE = 'Active',
}

class User
  implements
    DtoConvertible<IUpdatableUserObject>,
    UniqueIdentifiable,
    IValidatable,
    IHasSystemUniqueID
{
  /**
   * static system names
   */
  static SystemUserNames = {
    Anonymous: 'anonymous',
    Authenticated: 'authenticated',
    Admin: 'admin',
  };

  //TODO: https://cyolo.atlassian.net/browse/CY-10713
  status: Status = Status.WAITING_FOR_ENROLLMENT;

  /**
   * returns a new user instance
   * @param id
   * @param name
   * @param firstName
   * @param lastName
   * @param phoneNumber
   * @param email
   * @param personalDesktop
   * @param enrolled
   * @param enabled
   * @param enrollmentMethods
   * @param identityProviders
   * @param system
   * @param supervisor
   * @param totpEnrolled
    //TODO: https://cyolo.atlassian.net/browse/CY-10713
   * @param lastLogin
   * @param password
   * @param labels
   * @param mustChangePassword
   * @param sendEmail
   * @param totpSecret
   * @param inviteUser
   * @param mfaValidPolicies
   * @param emailEnrolled
   * @param smsEnrolled
   * @param lastLogout
   * @param simpleGroups
   * @param externalGroups
   //TODO: https://cyolo.atlassian.net/browse/CY-10713
   * @param lastSeen
   */
  constructor(
    public id = uuid(),
    public name = '',
    public firstName: string = '',
    public lastName: string = '',
    public phoneNumber = '',
    public email = '',
    public personalDesktop = '',
    public enrolled = false,
    public enabled = false,
    public enrollmentMethods: MfaId[] = [],
    public identityProviders: IdentityProviderDescriptor[] = [],
    public system = false,
    public supervisor: User | undefined = undefined,
    public totpEnrolled = false,
    public lastLogin = '',
    public labels: string[] = [],
    public password = '',
    public mustChangePassword = false,
    public sendEmail = false,
    public totpSecret?: string | undefined,
    public inviteUser = false,
    public mfaValidPolicies: string[] = [],
    public emailEnrolled = false,
    public smsEnrolled = false,
    public lastLogout = '',
    public simpleGroups: IBaseGroupObject[] = [],
    public externalGroups: IBaseGroupObject[] = [],
    public lastSeen: string = '',
    public fullName?: string | undefined,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.status = enrolled ? Status.ACTIVE : Status.WAITING_FOR_ENROLLMENT;
    //TODO: This is added to enable proper display/filter/suggestion
    // of date time values in general and zero values in particular
    //TODO: https://cyolo.atlassian.net/browse/CY-10713
    this.lastLogin = formatDate(this.lastLogin);
    this.lastSeen = formatDate(this.lastLogout);
  }

  kind = 'user';
  systemUniqueID = getSystemUniqueID(this);

  /**
   * returns if this user instance content is valid
   */
  get isValid(): boolean {
    // validate descriptors
    if (this.identityProviders.length === 0) return false;

    // validate password
    if (this.canResetPassword && !validator.complexPassword(this.password)) {
      return false;
    }

    // validate phone number if it exists
    if (this.phoneNumber.length > 0) {
      if (!validator.phoneNumber(this.phoneNumber)) return false;
    }

    // validate email if it exists
    if (this.email.length > 0 && !validator.email(this.email)) {
      return false;
    }

    // validate username
    if (!validator.notEmpty(this.name)) return false;

    return true;
  }

  /**
   * returns a formatted version of the user idp names
   */
  get formattedIdentityProviders(): string {
    return this.identityProviders.map((idp) => idp.idpName).join(', ');
  }

  get selectedIdp(): IdentityProviderDescriptor | undefined {
    if (this.identityProviders.length === 0) return undefined;
    return this.identityProviders[0];
  }

  get canResetPassword(): boolean {
    if (this.identityProviders.length === 0) return false;
    return this.identityProviders[0].idpType === 'local';
  }

  get personalDesktopList(): string[] {
    return this.personalDesktop ? this.personalDesktop.split(',') : [];
  }

  /**
   * returns a new empty user instance
   * @returns
   */
  static EmptyUser(): User {
    return new User(
      uuid(),
      '',
      '',
      '',
      '',
      '',
      '',
      false,
      true,
      [],
      [],
      false,
      undefined,
      undefined,
      '',
      [],
      '',
      true,
      true,
    );
  }

  /**
   * parses a raw user object
   * @param res
   * @returns
   */
  static fromRaw(res: IUserObject): User {
    const {
      id,
      name,
      first_name,
      last_name,
      phone_number,
      email,
      personal_desktop,
      enrolled,
      enabled,
      allowed_idps,
      sms_enrolled,
      totp_enrolled,
      email_enrolled,
      system,
      supervisor,
      last_login,
      labels,
      must_change_password,
      send_email,
      invite_user,
      mfa_valid_policies,
      last_logout,
      simple_groups,
      external_groups,
    } = res;
    // parse out identity providers
    const identityProviders =
      allowed_idps?.map(IdentityProviderDescriptor.fromRaw) ?? [];
    const supervisorUser =
      supervisor === null ? undefined : User.fromRaw(supervisor);

    // calculate the user mfa enrolled ids
    const mfaEnrolledIds = [];
    if (sms_enrolled) mfaEnrolledIds.push(MfaId.SMS);
    if (email_enrolled) mfaEnrolledIds.push(MfaId.EMAIL);
    if (totp_enrolled) mfaEnrolledIds.push(MfaId.TOTP);

    return new User(
      id,
      name,
      first_name,
      last_name,
      phone_number,
      email,
      personal_desktop,
      enrolled,
      enabled,
      mfaEnrolledIds,
      identityProviders,
      system,
      supervisorUser,
      totp_enrolled,
      last_login ?? '',
      labels,
      '',
      must_change_password,
      send_email,
      '',
      invite_user,
      mfa_valid_policies,
      email_enrolled,
      sms_enrolled,
      last_logout,
      simple_groups,
      external_groups,
    );
  }

  /**
   * setter for identity providers
   * @param idps
   */
  setIDP(...idps: IdentityProviderDescriptor[]) {
    this.identityProviders = idps;

    // resets password when the idp kind is not local
    if (!this.canResetPassword) {
      this.password = '';
    }
  }

  setPassword(password: string) {
    this.password = password;
  }

  didPassPolicyMfa(policyId: string): boolean {
    return this.mfaValidPolicies.includes(policyId);
  }

  /**
   * converts this user into a raw
   * user object used for server updates
   * @returns
   */
  toRaw(): IUpdatableUserObject {
    // if user is supervised, change enrolled and totpEnrolled to true
    if (this.supervisor != undefined) {
      this.enrolled = true;
      this.totpEnrolled = true;
    }

    let rawSupervisor;
    if (this.supervisor === undefined) {
      rawSupervisor = null;
    } else {
      rawSupervisor = Number(this.supervisor.id);
    }

    const rawObject: IUpdatableUserObject = {
      allowed_idps: this.identityProviders.map((idp) => Number(idp.id)),
      enabled: this.enabled,
      enrolled: this.enrolled,
      name: this.name,
      first_name: this.firstName,
      last_name: this.lastName,
      personal_desktop: this.personalDesktopList,
      phone_number: this.phoneNumber,
      email: this.email,
      totp_enrolled: this.totpEnrolled,
      supervisor: rawSupervisor,
      must_change_password: this.mustChangePassword,
      send_email: this.sendEmail,
      invite_user: this.inviteUser,
      email_enrolled: this.emailEnrolled,
      sms_enrolled: this.smsEnrolled,
    };

    // if password is defined add it to raw object
    if (this.password.length > 0) {
      rawObject.password = this.password;
    }

    if (this.totpSecret !== undefined) {
      rawObject.totp_secret = this.totpSecret;
    }

    return rawObject;
  }

  /**
   * deletes the user instance
   * @returns
   */
  async delete(): Promise<void> {
    return UserAPI.deleteUser(this.id);
  }

  /**
   * updates the user instance
   * @returns
   */
  async update(): Promise<void> {
    return UserAPI.updateUser(this.id, this.toRaw());
  }

  /**
   * creates the user instance
   * @returns
   */
  async create(): Promise<void> {
    return UserAPI.createUser(this.toRaw());
  }
}

export default User;
